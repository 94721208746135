let titleEl = document.querySelector('.currentShow-title')

function setTitle (title) {
  titleEl.classList.remove('state-loading')
  titleEl.textContent = title
}

async function loadTitle () {
  let url = 'https://foa-ice-02.ddns.net/status-json.xsl'
  let response = await window.fetch(url)
  let data = await response.json()
  let source = data['icestats']['source']
  if (Array.isArray(source)) {
    source = source[0]
  }
  let title = source['title']
  setTitle(title)
}

function scheduleLoadTitle () {
  window.requestAnimationFrame(() => {
    loadTitle()
    window.setTimeout(scheduleLoadTitle, 1000 * 5)
  })
}

scheduleLoadTitle()
